const terms_title = "תקנון ותנאי שימוש";

const terms_markdown = `
# תנאי שימוש באתר קהילה.טק

תקנון השימוש באתר הנ"ל נכתב בלשון זכר אך האמור בו מתייחס לנשים וגברים כאחד.

## 1. קדימון

אתר קהילה.טק (להלן "האתר") הוא אתר המשתמש כאתר לימוד טכנולוגיה והנך מוזמן לקחת בו חלק בכפוף להסכמתך לתנאי השימוש אשר יפורטו להלן. בנוסף השימוש באתר זה על כל תכניו והשירותים המוצעים בו, הורדות של קבצים, מדיה כגון תמונות וסרטונים והתכנים השונים המוצעים לקהל המבקרים עשויים להשתנות מעת לעת או בהתאם לסוג התוכן. הנהלת האתר שומרת לעצמה הזכות לעדכן את תנאי השימוש המוצגים להלן מעת לעת וללא התראה או אזכור מיוחד בערוצי האתר השונים.

## 2. קניין רוחני

האתר כמו גם כל המידע שבו לרבות עיצוב האתר, קוד האתר, קבצי מדיה לרבות גרפיקות, סרטונים, תמונות, טקסטים, קבצים המוצעים להורדה וכל חומר אחר אשר מוצג באתר שייכים במלואם לאתר, אך מובאים לציבור לשימוש ללא עלות וללא מגבלות למעט אלה המפורטות בתקנון זה, ובפרט בסעיף אחריות מטה.

## 3. תוכן האתר

אנו שואפים לספק לכם את המידע המוצג באתר ללא הפרעות אך יתכנו בשל שיקולים טכניים, תקלות צד ג או אחרים, הפרעות בזמינות האתר. ולכן איננו יכולים להתחייב כי האתר יהיה זמין לכם בכל עת ולא יינתן כל פיצוי כספי או אחר בשל הפסקת השירות / הורדת האתר. קישורים לאתר חיצוניים אינם מהווים ערובה כי מדובר באתרים בטוחים, איכותיים או אמינים וביקור בהם נעשה על דעתכם האישית בלבד ונמצאים בתחום האחריות הבלעדי של המשתמש באתר. התכנים המוצעים באתר הינם בבעלותם הבלעדית של קהילה.טק ואין לעשות בהם שימוש אשר נוגד את האמור בתקנון זה (ראה סעיף 3) למעט במקרים בהם צוין אחרת או במקרים בהם צוין כי זכויות היוצרים שייכים לגוף חיצוני. במקרים אלו יש לבדוק מהם תנאי השימוש בקישור המצורף ולפעול על פי המצוין באתר החיצוני לו שייכים התכנים.

## 4. ניהול משתמשים ומבקרים באתר

הנהלת האתר שומרת לעצמה את הזכות לחסום כל משתמש ובין אם על ידי חסימת כתובת הIP של המחשב שלו, כתובת הMACID של המחשב שלו או אפילו בהתאם למדינת המוצא ללא צורך לספק תירוץ אשר מקובל על הגולש. צוות האתר / הנהלת האתר יעשה כל שביכולתו להגן על פרטי המשתמשים הרשומים באתר / מנויים הרשומים באתר. במקרים בהם יעלה בידיו של צד שלישי להשיג גישה למידע מוסכם בזאת כי לגולשים, משתמשים וחברים באתר לה תהה כל תביעה, טענה או דרישה כלפי צוות האתר.

## 5. גילוי נאות

באתר זה עשוי לעשות שימוש בקבצי קוקיז (במיוחד עבור משתמשים רשומים ומנויים) ובממשקי סטטיסטיקה פנימיים בכדי לשמור תיעוד סטטיסטי אנונימי של גולשים וניתוח תנועת הגולש/ים, הרגלי גלישה באתר וניתוח קליקים וזמן שהייה. בכל העת ולבד מאשר גולשים המחוברים לאתר המידע הנשמר הוא אנונימי לחלוטין ואין בו את שם הגולש או כל פרט מזהה אחר.

## 6. אחריות

הנהלת האתר (ו/או מי מטעמה) אינה נושאת במישרין או בעקיפין באחריות כלשהי לנזקים הנובעים כתוצאה משימוש ו/או הסתמכות על מידע המתפרסם באתרים חיצוניים, שאליהם ניתן להגיע באמצעות איזה מהשירותים באתר. יובהר כי החברה עושה ותעשה כמיטב יכולתה לשתף פעולה עם ספקים אמינים ובעלי מוניטין בלבד. הנהלת האתר (ו/או מי מטעמה) אינה נושאת במישרין ו/או בעקיפין באחריות כלשהי לנזקים הנובעים ו/או הקשורים בכל דרך שהיא בשימוש ו/או בביצועי האתר. בכל מקרה הנהלת האתר לא תישא באחריות לפעילות כלשהי של כל גורם אחר שאינו בשליטתה המלאה.

## תכני האתר

האתר מאפשר לגולשיו לערוך חלק מדפי האתר, ובנוסף לשאול שאלות ולכתוב הודעות במגוון דרכים, לרבות איזורי שאלות ותשובות ופורומים. בעלי האתר אינם נושאים באחריות לתכנים ולדעות שמפרסמים גולשיו, ומדגיש שהאחריות הבלעדית על תוכן התגובות חלה על הגולשים. הנהלת האתר שומרת לעצמה את הזכות לערוך או למחוק כל תוכן שהוא ללא אזהרה מוקדמת, בגין כל סיבה כראות עיניה.

כאשר הנך עורך או מוסיף תוכן בכל דרך שהיא, הנך מעניק להנהלת האתר ולכל גולשיו רישיון קבוע, בלתי חוזר ובלתי מוגבל לשימוש בתוכן בכפוף לתקנון זה.

## 8. איזור שיפוט

הדין החל על תקנון זה ו/או על כל פעולה ו/או על סכסוך הנובע ממנו, הוא הדין הישראלי בלבד. בכל מקרה של מחלוקת, תהא לבתי המשפט (השלום או המחוזי) חיפה הסמכות הבלעדית לדון בה. הדין החל על השימוש באתר, על ההזמנה ועל תקנון זה, לרבות פרשנותו ואכיפתו של התקנון הוא הדין הישראלי בלבד.

## 9. יצירת קשר

ניתן ליצור קשר עם הנהלת האתר דרך עמוד יצירת הקשר הייעודי לכך.
`;

export { terms_title, terms_markdown };
