export function normalizeTitle(title) {
  return title.replaceAll("_", " ").trim();
}

// To be used by trivial URLs, like wiki links.
export function urlTitle(title) {
  return normalizeTitle(title).replaceAll(" ", "_");
}

// To be used when using `title` as HTTP CGI parameter.
export function encodeTitle(title) {
  return encodeURIComponent(normalizeTitle(title));
}
