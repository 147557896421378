const a11y_title = "הצהרת נגישות";

const a11y_markdown = `
# הצהרת הנגישות של אתר קהילה.טק

**על פי תיקון לתקנות העוסקות בנגישות האינטרנט אתר זה פטור מחובת נגישות. האתר אינו מציע שירותים כלל ומופעל על ידי אדם פרטי (לא עמותה או חברה מסחרית).**

אני משקיע זמן ומאמץ רב בהנגשת אתר האינטרנט מתחילת בנייתו. האתר מונגש לחלוטין בהתאם לתקנה 35, ת.י. 5568 להנגשת אתרי אינטרנט ו Wcag 2.0. בחרתי בתקן AA. בדף זה אפרט על הכלים והשיטות בהן השתמשתי להנגשת האתר וכן הסבר איך לפנות אלי אם יש צורך בתיקון תקלה או שיפור הנגישות.

האתר הונגש באופן הבא:

1. התאמת האתר לכל סוגי הדפדפנים המודרניים
2. התאמת האתר לכל סוגי הפלטפורמות – מובייל, טבלטים ודסקטופים.
3. התאמת האתר מבחינת שימוש במקלדת בלבד.
4. התאמת האתר לאנשים עם לקות ראיה חלקית או מלאה.
5. התאמת האתר והתכנים באתר לאנשים עם לקות שמיעתית חלקית או מלאה.

על מנת להנגיש את האתר ותכניו, נעשו השינויים הבאים:

1. שינויים ובדיקות באתר על מנת שיתאים לכל הדפדפנים ולכל הפלטפורמות (ריספונסיביות).
2. הוספת access keys באופן שאינו יסתור את פעולות המקלדת הטבעיות ווידוא שניתן להגיע לחלקי אתר שונים עם מקלדת בלבד.
3. בדיקת ניגודיות בצבעים, הוספת טקסט הסבר לתמונות באתר.

כפי שניתן לראות, בהנגשת האתר הושקעו זמן ומאמצים מרובים. באתר מאמרים, מדריכים ותכנים נוספים רבים וייתכן שיש בו בעיות נגישות בחלק קטן מהדפים.

במידה ויש בעיות נגישות, ניתן לפנות אלי באמצעות המייל kehila.tech בג'ימייל.קום
`;

export { a11y_title, a11y_markdown };
