import "./Search.css";

import React, { useState } from "react";
import Loading from "./Loading";
import axios from "axios";
import WikiLink from "./WikiLink";
import H1 from "./H1.js";

export default function Search() {
  const [query, setQuery] = useState("");
  const [searchedQuery, setSearchedQuery] = useState("");
  const [results, setResults] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  if (isLoading === 0) {
    return <Loading />;
  }

  const onSearch = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsLoading(true);
    setError(false);
    setResults(undefined);
    setSearchedQuery(query);
    axios
      .get(`/api/page/search/${encodeURIComponent(query)}`)
      .then((res) => {
        setIsLoading(false);
        if (res.data.error) {
          setError(true);
        } else {
          setResults(res.data);
        }
      })
      .catch((err) => {
        // TODO: show error
        console.log(`Unable to fetch latest changes: ${err}`);
        setError(true);
      });
  };

  const full_title = searchedQuery
    ? `תוצאות חיפוש '${searchedQuery}'`
    : "חיפוש";

  return (
    <>
      <H1 title={full_title} />

      <div className="editor-container">
        <div className="flat-container">
          <form>
            <span className="icon-in-input material-icons">search</span>
            <input
              className="query"
              type="text"
              placeholder="מה לחפש?"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            ></input>
            <button onClick={onSearch}>חיפוש</button>
          </form>

          {isLoading ? <Loading /> : undefined}
          {error || results ? <hr /> : undefined}
          {error ? <p>שגיאה בהרצת החיפוש</p> : undefined}
          {results === undefined ? undefined : (
            <>
              <h2>תוצאות עבור '{searchedQuery}':</h2>
              {results.length === 0 ? (
                <p>
                  אין תוצאות... אולי תצרו את העמוד{" "}
                  <WikiLink title={searchedQuery} />?
                </p>
              ) : (
                results.map((r, i) => (
                  <div className="result" key={i}>
                    <WikiLink title={r.title} />
                    <span
                      className="snippet"
                      dangerouslySetInnerHTML={{ __html: r.snippet }}
                    ></span>
                  </div>
                ))
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
