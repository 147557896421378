import {
  $isYouTubeNode,
  YouTubeNode,
  $createYouTubeNode,
} from "./YouTubeNode.tsx";

const regex = /<YOUTUBE:([A-Za-z0-9_-]*)>$/;

export const YOUTUBE_NODE_TRANSFORMER = {
  dependencies: [YouTubeNode],
  export: (node, _, dom) => {
    if (!$isYouTubeNode(node)) {
      return null;
    }

    return `<YOUTUBE:${node.getId()}>`;
  },
  importRegExp: regex,
  regExp: regex,
  replace: (textNode, match) => {
    const id = match[1];
    const linkNode = $createYouTubeNode(id);
    textNode.replace(linkNode);
  },
  trigger: ")",
  type: "text-match",
};
