import { useEffect } from "react";

export default function H1(props) {
  const title = props.title;

  useEffect(() => {
    document.title = `${title} | קהילה.טק`;
  });

  return (
    <div className="sub-header">
      <h1>{title}</h1>
    </div>
  );
}
