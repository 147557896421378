const main_title = "ראשי";

const main_markdown = `
ברוכים הבאים ל**קהילה.טק**!

# מה יש כאן?

קהילה.טק מלמד טכנולוגיה בעברית ובקלות.

כאן תוכלו תמצאו קורסי וידאו ומאמרים במגוון נושאים טכנולוגיים, בחינם ובלי פרסומות.

# כמה זה עולה?

האתר הוא מיזם ללא מטרות רווח, ו**כל תכניו ניתנים באופן חופשי ובחינם לחלוטין**.

כל המידע והקורסים מוגשים ללא הרשמה, בלי פרסומות, בלי תשלום ובלי תנאים.

האתר נוצר בהתנדבות, ללא מטרות רווח, וכל העלויות הכרוכות בו משולמות ע"י מפעיליו.

# מאיפה מתחילים?

אם אתם מסוג האנשים שמעדיפים ללמוד מהרצאות וידאו - לגמרי תתחילו מעמוד ה[קורסים](/wiki/קורסים).

אם טקסט זה הקטע שלכם - ה[ויקי](/wiki/ויקי) שלנו מחכה לכם.

בהצלחה!

# אפשר לעזור?

כן, תודה!

תמיד יש עוד עבודה, בין אם בכתיבת תוכן, הגהה, עיצוב, הקלטת קורסים חדשים, או הפצה.

לא משנה מה תחום ההתמחות שלכם (אם בכלל), נשמח לעזרה! בקרו בעמוד [עזרו לנו](/wiki/עזרו_לנו) או [צרו איתנו קשר](/about)!

# גלישה מהנה!

מוזמנים לעקוב אחרינו [בטוויטר](https://twitter.com/KehilaTech) או [ביוטיוב](https://www.youtube.com/channel/UCU-v9EP7eHdPjecJOHejGXw) לעדכונים.
`;

export { main_title, main_markdown };
