import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { YouTubeNode } from "./YouTubeNode.tsx";
import { ImageNode } from "./ImageNode";
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";

const nodes = [
  HeadingNode,
  ListNode,
  ListItemNode,
  QuoteNode,
  CodeNode,
  CodeHighlightNode,
  TableNode,
  TableCellNode,
  TableRowNode,
  AutoLinkNode,
  LinkNode,
  YouTubeNode,
  HorizontalRuleNode,
  ImageNode,
];

export default nodes;
