import { TRANSFORMERS } from "@lexical/markdown";
import { YOUTUBE_NODE_TRANSFORMER } from "./YouTubeTransformer";
import { HR_NODE_TRANSFORMER } from "./HRTransformer";
import { IMAGE_NODE_TRANSFORMER } from "./ImageTransformer";

const transformers = [
  HR_NODE_TRANSFORMER,
  YOUTUBE_NODE_TRANSFORMER,
  IMAGE_NODE_TRANSFORMER,
  ...TRANSFORMERS,
];

export default transformers;
