const about_title = "אודות";

const about_markdown = `
# שלום!

קוראים לי שחר, נעים מאוד :)

אני מהנדס תוכנה כבר 20 שנים. בוגר 8200, עבדתי ב- Microsoft, Google וכמה סטארט-אפים, אבל למעשה אני מתכנת מאז שהייתי ילד.

החלטתי להקים את האתר הזה בשביל להנגיש תוכן בצורה פשוטה, בעברית, לכל מי שמעוניין.

החזון שלי הוא להפוך את האתר לקהילה וירטואלית בה יהיו דיונים פעילים, ושאלות ובעיות של גולשים יקבלו מענה מגולשים אחרים.

בטווח הקצת-יותר-רחוק אני מעוניין להפוך את קהילה.טק למעין תנועת נוער עבור נערים ונערות שמתעניינים.ות בטכנולוגיה, ומעוניינים.ות ללמוד וללמד אחרים.

האתר כולו מוקם ומתוחזק בהתנדבות, ללא מטרות רווח, ללא פרסומות ואינו דורש תשלום או הרשמה. כל התכנים בו חופשיים לשימוש ולהפצה מחדש. תהנו!

שאלות, הצעות ורעיונות אפשר להפנות ל- kehila.tech בג'ימייל.קום.
`;

export { about_title, about_markdown };
