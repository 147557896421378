import "./Markdown.css";

import AutoLinkPlugin from "./lexical/AutoLinkPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import CodeHighlightPlugin from "./lexical/CodeHighlightPlugin";
import { $convertFromMarkdownString, TRANSFORMERS } from "@lexical/markdown";
import theme from "./lexical/theme";
import nodes from "./lexical/nodes";
import transformers from "./lexical/Transformers";

function onError(error) {
  console.error(error);
}

export default function Viewer({ markdown }) {
  const initialConfig = {
    editable: false,
    theme,
    onError,
    nodes,
    editorState: () => $convertFromMarkdownString(markdown, transformers),
  };

  return (
    <>
      <LexicalComposer initialConfig={initialConfig} className="editor">
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <CodeHighlightPlugin />
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
        </div>
      </LexicalComposer>
    </>
  );
}
