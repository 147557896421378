import "./Loading.css";

export default function Loading() {
  return (
    <>
      <p className="loading-text">טוען...</p>
      <div className="loadingio-spinner-ripple-b9ctpvngrvf">
        <div className="ldio-rnahtw5hodf">
          <div></div>
          <div></div>
        </div>
      </div>
    </>
  );
}
