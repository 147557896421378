import "./LatestWikiChanges.css";

import React, { useState, useEffect } from "react";
import Loading from "./Loading";
import axios from "axios";
import WikiLink from "./WikiLink";
import H1 from "./H1.js";

export default function LatestWikiChanges() {
  const [changes, setChanges] = useState([]);

  useEffect(() => {
    axios
      .get(`/api/page/latest`)
      .then((res) => {
        setChanges(res.data);
      })
      .catch((err) => {
        // TODO: show error
        console.log(`Unable to fetch latest changes: ${err}`);
      });
  }, []);

  if (changes.length === 0) {
    return <Loading />;
  }

  return (
    <>
      <H1 title="שינויים אחרונים" />
      <table className="latest-changes">
        <thead>
          <tr>
            <td>עמוד</td>
            <td>זמן שינוי</td>
          </tr>
        </thead>
        <tbody>
          {changes.map((e, i) => (
            <tr key={i}>
              <td>
                <WikiLink title={e.title} />
              </td>
              <td>{e.modified}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
